import '../styles/index.scss';
import * as Rellax from 'rellax';

if (process.env.NODE_ENV === 'development') {
    require('../index.html');
}

var rellax = new Rellax('.rellax', {
    breakpoints: [375, 768, 1200]
});

const header = document.getElementById('header');

window.addEventListener('scroll', (e) => {
    if (window.scrollY > 10) {
        if (!header.classList.contains('scrolled')) {
            header.classList.add('scrolled');
        }
    } else {
        header.classList.remove('scrolled');
    }
});

document.querySelectorAll("[href^='#']").forEach(
    link => link.addEventListener('click', e => smoothScroll(e))
);

document.querySelectorAll("[href='https://app.odontozen.com/auth/register']").forEach(
    link => link.addEventListener('click', e => {
        e.stopPropagation();
        e.preventDefault();
        const position = e.target.dataset?.location;
        gtag('event', 'sign_up', {
            'event_category': 'engagement',
            'event_label': position
        });
        analytics.track('Click Signup Button', {position});
        window.location.href = 'https://app.odontozen.com/auth/register?ajs_aid=' + analytics.user().anonymousId();
    })
);

const emailContact = document.getElementById('email-contact');
const email = ['support', 'odontozen.com'];
emailContact.setAttribute('href', 'mailto:' + email.join('@'));
emailContact.getElementsByTagName('span')[0].innerHTML = email.join('@');

var smoothScroll = e => {
    e.preventDefault();
    document.querySelector(e.target.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
    });
};

class Video {
    constructor(videoWrapper, name) {
        this.name = name;
        this.videoWrapper = videoWrapper;
        this.video = this.videoWrapper.querySelector("video");
        this.formatVideoPlayButton();
        this.renderVideoPlayButton();
    }

    renderVideoPlayButton() {
        this.video.classList.add('has-media-controls-hidden');
        this.videoPlayButton = this.videoWrapper.querySelector(".video-overlay-play-button");
        this.videoPlayButton.addEventListener('click', this.hideVideoPlayButton.bind(this));
    }

    formatVideoPlayButton() {
        this.videoWrapper.insertAdjacentHTML('beforeend', '\
        <svg class="video-overlay-play-button" viewBox="0 0 200 200" alt="Play video">\
            <circle cx="100" cy="100" r="90" fill="none" stroke-width="15" stroke="#fff"/>\
            <polygon points="70, 55 70, 145 145, 100" fill="#fff"/>\
        </svg>\
    ');
    }

    hideVideoPlayButton() {
        this.video.play();
        this.videoPlayButton.classList.add('is-hidden');
        this.video.classList.remove('has-media-controls-hidden');
        this.video.setAttribute('controls', 'controls');
        gtag('event', 'video_played', {
            'event_category': 'engagement',
            'event_label': this.name
        });
        analytics.track('Video Played', {name: this.name});
    }

}

new Video(document.getElementById('video1'), 'Lema');
new Video(document.getElementById('video2'), 'Edgar');

class Plan {
    constructor(planWrapper, basicPrice) {
        this.planWrapper = planWrapper;
        this.priceDiv = planWrapper.querySelector('.plan-price');
        this.userdDiv = planWrapper.querySelector('.plan-users');
        this.basicPrice = basicPrice;
        this.patientsPrice = 0;
        this.dentistsPrice = 0;

        planWrapper.querySelector('.plan-patients').addEventListener('change', (event) => {
            this.patientsPrice = Number(event.target.value);
            this.setPrice();
        });

        planWrapper.querySelector('.plan-dentists').addEventListener('input', (event) => {
            this.dentistsPrice = Number(event.target.value);
            this.setPrice();
        });
    }

    setPrice() {
        this.priceDiv.textContent = (this.basicPrice + this.patientsPrice + this.dentistsPrice).toString();
        this.userdDiv.textContent = (this.dentistsPrice / 5 + 2).toString();
    }
}

new Plan(document.getElementById('plan-1'), 19);
new Plan(document.getElementById('plan-2'), 39);
